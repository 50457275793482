.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.header {
  background-color: #f78429;
  padding: 10px;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

.cell {
  border: 1px solid #ddd;
  padding: 8px;
}

.tableContainer {
  overflow: auto;
  max-height: calc(100vh - 200px);
  max-width: 100%;
  border-bottom: #333333 solid 2px;
}

.cell,
.header {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.buttonGroup {
  display: flex;
  gap: 10px;
}

.headerTitle {
  margin: 0;
}

.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  /* Adjust width as needed */
  max-width: 50%;
  background-color: #f4f4f4;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}

.sidebar.show {
  transform: translateX(0);
}

.containerSidebarOpen {
  width: calc(100% - 350px);
  min-width: calc(50% - 45px);
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.sidebarContent {
  margin-top: 40px;
}

.sheetButton {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 0 0 4px 4px;
  border-width: 1px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin-right: 10px;
  padding: 5px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.sheetButton:last-child {
  margin-right: 0;
}

.sheetButton:hover {
  background-color: rgba(51, 51, 51, 0.1);
}

.sheetButton.active {
  background-color: #f78429;
  color: white;
}
