.sidebar {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 300px; /* Adjust width as needed */
    background-color: #f4f4f4;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    padding: 20px;
    overflow-y: auto;
}

.sidebar.show {
    transform: translateX(0);
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}

.closeButton {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.sidebarContent {
    margin-top: 20px;
}
